import { forwardRef, useRef } from "react";
import ReactDOM from "react-dom";
import { GetAQuoteForm } from "./GetAQuoteForm";
import { CloseButton, GetAQuotePopoverDrawer, Overlay, Title } from "./styled";

export const GetAQuotePopover = forwardRef(
  ({ onClose, isVisible = false, ...restProps }, forwardedRef) => {
    const formRef = useRef(null);

    const handlePopoverClose = () => {
      if (onClose) onClose();
      if (!formRef.current) return;
      formRef.current.cleanForm();
    };

    const handleSubmit = (response) => {
      if (!response.success) return;
      handlePopoverClose();
      // Scroll to the top since the form will perform a navigation to "/ThankYou"
      window.scrollTo(0, 0);
    };

    return ReactDOM.createPortal(
      <>
        {isVisible && <Overlay onClick={handlePopoverClose} />}
        <GetAQuotePopoverDrawer
          {...restProps}
          ref={forwardedRef}
          $isVisible={isVisible}
        >
          <CloseButton onClick={handlePopoverClose} />
          <Title>Talk to a Human</Title>
          <GetAQuoteForm onSubmit={handleSubmit} ref={formRef} />
        </GetAQuotePopoverDrawer>
      </>,
      document.body
    );
  }
);
