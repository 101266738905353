import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";

const Slider = () => {
  const [slides, setSlides] = useState(2)
  useEffect(()=>{
    if (window.screen.width < 550) {
      setSlides(1)
    }
  },[])

  return (
    <>
      <Swiper
        slidesPerView={slides}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        spaceBetween={30}
        modules={[Pagination,Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className='content-process yelow'>
                        <h3>WE LISTEN</h3>
                        <p>At our first meeting, your idea takes center stage. Be prepared to share your needs, goals and expectations for your project. 
                            Our objective is to achieve a thorough understanding of the work at hand so that we can find the solutions you seek. 
                            From the very beginning, we establish a culture of open communication and confidence – the more you share, the better the result. 
                            Transparency and collaboration are key. We work within the constraints of your reality, including your budget. 
                            If you can provide a range you are willing to commit to a project, we will ensure you get the most out of your investment. 
                            With this knowledge, we then outfit you with a team of experienced 3D and CG artists, technologists and project managers. 
                            Over the years 3DW has mastered the art of matching the best teams with our clients. 
                            Our specialized team of creatives will stick with you, through thick and thin, to the completion of your project.</p>
                        <div className='row'>
                            <div className='cuadrado black'></div>
                        </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='content-process'>
                        <h3>CREATE</h3>
                        <p>Our team is a very hands-on and is directly involved with the tai-loring of teams while also overseeing the quality of our work and services. Our team personally works with all clients at some point during the execution phase, curating packages to improve customer success and inspecting final touces with his eye for design. 3DW prides itself on comprehensive understanding of the marker, attention to detail, and personal service to all clients. In today's digital age, we understand the importance of digital innovation and we aren't afraid to pick up the phone, jump into a quick zoom call or met one-to-one.</p>
                        <div className='row'>
                            <div className='cuadrado'></div><div className='cuadrado'></div>
                        </div>
                        
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='content-process'>
                        <h3>& DELIVER</h3>
                        <p>Once we have reached the end of the timeline, updated all of your feedback and made all of the necessary adjustments as per the agreed number of revisions, the creation process is complete. 3DW delivers the final product to you as a proud design partner and collaborator. We guarantee your full satisfaction with the results of our partnership and with our high-quality work, along with an incomparable sense of fulfillment; all on the expected date - we allways deliver on time, every time.</p>
                        <div className='row'>
                        <div className='cuadrado'></div><div className='cuadrado'></div><div className='cuadrado'></div>
                        </div>
            </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default Slider
