import React,{useState, useEffect, useCallback} from 'react';
import { ContentHeader, Button } from '../Styles/Styles';
import { Link } from "react-router-dom";
import {IoChevronDownOutline} from "react-icons/io5";
import {BsTelephone} from 'react-icons/bs'
import './Header.scss'
const Header = (props) => {
    const [y, setY] = useState(window.scrollY);
    const [scrollMenu, setScroll] = useState('')
    const [logo, setLogo] = useState('/assets/img/logo-white.png')
    const [menuMobile, setMenuMobile] = useState(false)
    const [path, setPath] = useState(window.location.pathname)

    const handleNavigation = useCallback(
        (e) => {
          const window = e.currentTarget;
          if (y > window.scrollY) {
              if(window.scrollY < 200){
                  setScroll('')
                  setLogo('/assets/img/logo-white.png')
             }
          } else if (y < window.scrollY) {
              if(window.scrollY > 200){
                  setScroll('back_menu')
                  setLogo('/assets/img/logo-Negro.png')
              }
          }
          setY(window.scrollY);
        },
        [y]
    );
    const handleMenu = (type) =>{
        if(window.innerWidth <= 1024){
            props.setOpen(props.open === 'menu-open' ? '' : 'menu-open')
        }
       
        window.scrollTo(0, 0)
    }

    window.addEventListener('resize', function(event){
        if (window.innerWidth  <= 1024) {
            setMenuMobile(true)
        }else{
            setMenuMobile(false)
        }
    });

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        if (window.innerWidth  <= 1024) {
            setMenuMobile(true)
        }
        return () => {
          window.removeEventListener("scroll", handleNavigation);
        };
      }, [handleNavigation]);


    return ( 
        <ContentHeader className={`${scrollMenu}`}>
            <div className='logo'>
                <a href="/"><img src={logo} alt="" /></a>
            </div>
            {
                !menuMobile &&
                <div className='menu'>
                    <ul>
                        <li id='services'>Services <IoChevronDownOutline></IoChevronDownOutline>
                        {/* <img src={iconService} alt="" /> */}
                            <ul>
                                <li id='Rendering-Services' onClick={()=> handleMenu('rendering')}><Link to={'/Photorealistic-Rendering-Services'}>Rendering</Link></li>
                                <li id='Animation-Services' onClick={()=> handleMenu('animation')}><Link to={'/Animation-Services'}>Animation</Link></li>
                                <li id='Virtual-Reality-Services' onClick={()=> handleMenu('virtual')}><Link to={'/Virtual-Reality-Services'}>Virtual Reality</Link></li>
                                <li id='Interactive-Services' onClick={()=> handleMenu('interactive')}><Link to={'/Interactive-Services'}>Interactive</Link></li>
                                <li id='Branding-Services' onClick={()=> handleMenu('branding')}><Link to={'/Branding-Services'}>Branding</Link></li>
                                <li id='VFX-Services' onClick={()=> handleMenu('vfx')}><Link to={'/VFX-Services'}>VFX</Link></li>
                            </ul>
                        </li>
                        <li id='About' onClick={()=> handleMenu('about')}><Link to={'/About'}>About</Link></li>
                        <li id='News' onClick={()=> handleMenu()}><Link to={'/News'}>News</Link></li>
                        <li id='Contact' onClick={()=> handleMenu('contact')}><Link to={'/Contact'}>Contact</Link></li>
                        {/* <li><a target='_blank' href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=Diego%20Lares%20%3Cdiego@3dworld.nyc%3E)">Contact</a></li> */}
                        <a className='phone' href="tel:2122037343"> <BsTelephone></BsTelephone> 212-203-7343</a>
                        {/* <Button black onClick={()=>handleForm()}>Get a Quote</Button> */}
                    </ul>
                </div>
            }
            {
                menuMobile &&
                <div className='menu'>
                    <ul>
                        <li id='About' onClick={()=> handleMenu('about')}><Link to={'/About'}>About</Link></li>
                        <li id='Rendering-Services' onClick={()=> handleMenu()}><Link to={'/Photorealistic-Rendering-Services'}>Rendering</Link></li>
                        <li id='Animation-Services' onClick={()=> handleMenu()}><Link to={'/Animation-Services'}>Animation</Link></li>
                        <li id='Virtual-Reality-Services' onClick={()=> handleMenu()}><Link to={'/Virtual-Reality-Services'}>Virtual Reality</Link></li>
                        <li id='Interactive-Services' onClick={()=> handleMenu()}><Link to={'/Interactive-Services'}>Interactive</Link></li>
                        <li id='Branding-Services' onClick={()=> handleMenu()}><Link to={'/Branding-Services'}>Branding</Link></li>
                        <li id='VFX-Services' onClick={()=> handleMenu()}><Link to={'/VFX-Services'}>VFX</Link></li>
                        <li id='News' onClick={()=> handleMenu()}><Link to={'/News'}>News</Link></li>
                        
                    </ul>
                    <div className='bottom'>
                        <Link id='Contact' onClick={()=> handleMenu('contact')} to={'/Contact'}>Contact</Link>
                        {/* <a target='_blank' href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=Diego%20Lares%20%3Cdiego@3dworld.nyc%3E)">Contact</a> */}
                        <div className='social'>    
                            <a  target={'_blank'} href="https://www.instagram.com/3dworldny/"><img src="/assets/img/insta.png" alt="Instagram" /></a>
                            <a target={'_blank'} href="https://www.linkedin.com/company/3dworldny"><img src="/assets/img/linkedin-black.png" alt="Linkedin" /></a>
                        </div>
                    </div>
                    <div className='city'>
                    <p>New York | Austin</p>
                    </div>
                </div>
            }
            <div className='icon_menu'>
                    <p className="btn-toggle" onClick={()=> handleMenu()} ><span></span></p>
            </div>
        </ContentHeader>
     );
}
 
export default Header;