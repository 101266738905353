import React, { useState, useEffect } from 'react';
import FrontPage from '../Components/Services/FrontPage';
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import PaginationBlog from '../Components/Pagination';
import './style.scss'
import Associated from '../Components/Associated';
const ArticleDetail = () => {
    const [isLastPage, setIsLastPage] = useState()
    const [isFirstPage, setIsFirstPage] = useState()
    const [articulo, setArticulo] = useState([])
    const { slug } = useParams();
    const [articulos, setArticulos] = useState([]);
    const [articulosAll, setArticulosAll] = useState([]);

    let navigate = useNavigate()
    useEffect(() => {
        axios({
            method: 'get',
            // url: `http://127.0.0.1:8000/blog/${slug}`,
            url: `https://panelblog.3dworld.nyc/blog/${slug}`,
        }).then(function (response) {
            // console.log(response)
            setArticulo(response.data);
            window.scrollTo(0, 0)
            axios({
                method: 'get',
                // url: `http://127.0.0.1:8000/blog/next/${response.data.id_category}/${response.data.id_article}`,
                url: `https://panelblog.3dworld.nyc/blog/next/${response.data.id_category}/${response.data.id_article}`,
            }).then(function (response) {
                //  console.log(response.data,"articulos")
                setArticulos(response.data)
                axios({
                    method: 'get',
                    // url: `http://127.0.0.1:8000/blog/next/article`,
                    url: `https://panelblog.3dworld.nyc/blog/next/article`,
                }).then(function (response) {
                    //  console.log(response)
                     setArticulosAll(response.data)
    
                });

            });
        });
    },[slug])

    useEffect(() => {
        if (!articulosAll.length) return;
        const currentPageIndex = articulosAll.findIndex((element) => {
            return element.slug_article === slug
        })
        if (!currentPageIndex) return;
        const isLastPage = articulosAll[currentPageIndex + 1];
        const isFirstPage = articulosAll[currentPageIndex - 1]
        if (!isLastPage) setIsLastPage(true)
        if (!isFirstPage) setIsFirstPage(true)
    }, [articulos])

    const handleNextItem = () => {
        if (!articulosAll.length) return;
        const currentPageIndex = articulosAll.findIndex((element) => {
            return element.slug_article === slug
        })
        // console.log(currentPageIndex)
        const nextBlog = articulosAll[currentPageIndex + 1];
        if (!nextBlog) return setIsLastPage(true)

        // window.location= `/News/${nextBlog.slug_article}`
        navigate(`/News/${nextBlog.slug_article}`);
    }

    const handleBackItem = () => {
        if (!articulosAll.length) return;
        const currentPageIndex = articulosAll.findIndex((element) => {
            return element.slug_article === slug
        })
        // console.log(currentPageIndex)
        const nextBlog = articulosAll[currentPageIndex - 1];
        if (!nextBlog) return setIsFirstPage(true)
        navigate(`/News/${nextBlog.slug_article}`);
    }
    return ( 
        <div className='body-content'>
            {
                articulo.url_front_full &&
                <FrontPage alt={'NYC Skyline View - 3D World renderings, Inc'}  portada={`https://panelblog.3dworld.nyc${articulo.url_front_full}`} name={''} subtitule={""}></FrontPage>
            }
                {/* <FrontPage alt={'NYC Skyline View - 3D World renderings, Inc'}  portada={`http://127.0.0.1:8000${articulo.url_front_full}`} name={''} subtitule={""}></FrontPage> */}
                <div className='content_article'>
                    <div className='title'>
                        <h2>{articulo.title_article}</h2>
                    </div>
                    <div className='content_text' dangerouslySetInnerHTML={{ __html: articulo.description_article}}>
                    </div>
                </div>
                <div className='pagination_boton'>
                    <a disabled={isFirstPage} onClick={handleBackItem}>Previous Article</a>
                    <a disabled={isLastPage} onClick={handleNextItem}>Next Article</a>
                </div>
                {
                    articulos.length > 0  &&
                    <Associated array={articulos}></Associated>
                }
        </div>
     );
}
 
export default ArticleDetail;