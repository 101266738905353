import React, { useState, useEffect } from "react";
import FrontPage from "../Components/Services/FrontPage";
import ConceptService from "../Components/Services/ConceptService";
import "./style.scss";
import { ContentTeam, ContentClientes } from "../Styles/Styles";
import useDocumentTitle from "../Components/UseDocumentTitle";
const About = () => {
  const [logoMobile, setLogoMobile] = useState(
    "/assets/img/clientes-white.png"
  );
  const [path, setPath] = useState(window.location.pathname);
  console.log(path.split("/").join(""));
  useDocumentTitle("About | 3D World renderings, Inc.");
  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setLogoMobile("/assets/img/clientes-white-mobile.png");
    } else {
      const activeNot = document.getElementsByClassName("activeService");
      const activeNot2 = document.getElementsByClassName("activeli");
      if (activeNot.length == 1) {
        activeNot[0].classList.remove("activeService");
      }
      if (activeNot2.length == 1) {
        activeNot2[0].classList.remove("activeli");
      }
      // const active = document.getElementById(path.slice(1))
      const active = document.getElementById(path.split("/").join(""));
      active.classList.add("activeService");
    }
  }, []);
  return (
    <div className="body-content">
      <FrontPage
        alt={"NYC Skyline View - 3D World renderings, Inc."}
        portada={"/assets/img/services/NYC_Skyline_View-3D_World_rendering.jpg"}
        name={"About"}
        subtitule={"Us"}
      ></FrontPage>
      <ConceptService>
        <div className="title">
          <h2>About</h2>
        </div>
        <p className="about">
          3D World is a New York City creative studio that has mastered the art
          of 3D technology to create photorealistic marketing campaigns and
          branding solutions for real estate developers, architects & designers
          both locally and internationally since 2012.
        </p>
        <p>We make experiencing your ideas possible.</p>
      </ConceptService>
      <div className="studio-about">
        <div className="title">
          <h2>The Studio</h2>
        </div>
        <p>
          Located at 33 Irving Place, 3D World provides highly personalized,
          face-to-face services to our NYC clients, as well as virtual
          consultations to our clientele reaching all corners of the map.
          Whatever the nature of your project might be, rest assured you will
          find extraordinary value in envisioning your ideas to life! The 3DW
          family includes skilled groups of architects, designers, experienced
          3D | CGI artists, technologists, and project managers. For over a
          decade, we at 3DW have been mastering the art of curating
          collaborative teams to aid our clients in projects of all sizes. Each
          carefully selected team enhances the design process with collaboration
          and feedback from you until a project is completed. If you’re a real
          estate developer, architect, interior designer, contractor, homeowner,
          event planner, branding and/or marketing agency looking to take your
          conceptual idea to the next level, we can help your business reach its
          goals.
        </p>
      </div>
      <div className="leadership">
        <div className="title">
          <h2>Leadership</h2>
        </div>
        <div className="cards">
          <div className="card">
            <img src="/assets/img/leadership/Photo-2.png" alt="" />
            <h3>Alex Ocampo</h3>
            <p>Studio Manager</p>
          </div>
          <div className="card">
            <img src="/assets/img/leadership/Photo-1.png" alt="" />
            <h3>Enrique Ramirez</h3>
            <p>Branding Director</p>
          </div>
          <div className="card">
            <img src="/assets/img/leadership/Photo-3.png" alt="" />
            <h3>Diego Lares</h3>
            <p>Founder & Director</p>
          </div>
          <div className="card">
            <img src="/assets/img/leadership/Photo-5.jpg" alt="" />
            <h3>Alex Marin</h3>
            <p>VFX Director</p>
          </div>
          <div className="card">
            <img src="/assets/img/leadership/Photo.png" alt="" />
            <h3>Marie Gauthier</h3>
            <p>Admin Manager</p>
          </div>
        </div>
      </div>
      <ContentTeam>
        <img
          src="/assets/img/NYC_Rendering_Studio_3D_World_renderings_Inc.jpg"
          alt="NYC Rendering Studio 3D World renderings, Inc.jpg"
        />
      </ContentTeam>
      <ContentClientes white>
        <h2>Our Clients & Collaborators</h2>
        <img
          src={logoMobile}
          alt="Clients & Collaborators - 3D World renderings, Inc.jpg"
        />
      </ContentClientes>
    </div>
  );
};

export default About;
