import React, { useState } from 'react';
import styled from 'styled-components'
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import swal from 'sweetalert';
import { Button } from '../Styles/Styles';

const Formulario = (props) => {
    const [recaptcha, setRecaptcha] = useState("")
    const [errorRecaptcha, showError] = useState("ocultar-error")

    const onChange = (val) => {
        setRecaptcha(val)
    }
    const formik = useFormik({
        initialValues: {
          nombre: '',
          tel: '',
          email: '',
          referencia: '',
          project: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required('required field'),
            tel: Yup.string().min(10,'Must be at least 10 digits').max(10,'Must be 10 digits maximum').required('required field'),
            email: Yup.string().email('invalid email').required('required field'),
            referencia: Yup.string().required('required field'),
            project: Yup.string().required('required field')
        }),
        onSubmit: async values => {
            // console.log(values)
            const payload = {
                ...values,
                recaptcha: recaptcha 
            }
            if(recaptcha !== ''){
                swal("Sending", "The mail is being sent", "success");
                 const res = await axios.post('http://demo.renderinc.mx/php/index.php', payload)
                //  console.log(res,'respuesta')
                 if(res.data.success){
                     swal("Sent", "The mail has been sent correctly", "success");
                     formik.resetForm();
            //         var link = document.createElement('a');
            //         link.href = props.urlBrochure;
            //         link.download = 'Brochure.pdf';
            //         link.dispatchEvent(new MouseEvent('click'));
                }else{
                     if(res.data.error){
                         swal("Error", "There was a problem sending the email", "error");
                     }
                }
            }else{
                showError('error')
            }
        },
      });
    return ( 
        <>
            <Form>
                <div className='contenedor_form'>
                    <p className='closed' onClick={()=>props.setShowModal(false)}>X</p>
                    <h1>Let's Get Started</h1>
                         <form onSubmit={formik.handleSubmit}>
                            <div className='row'>
                                <div className='column'>
                                    <input
                                        id="nombre"
                                        name="nombre"
                                        type="text"
                                        placeholder='Name*'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.nombre}
                                    />
                                    {formik.touched.nombre && formik.errors.nombre ? (
                                        <div>{formik.errors.nombre}</div>
                                    ) : null}
                                </div>
                                <div className='column'>
                                    <input
                                        id="tel"
                                        name="tel"
                                        type="number"
                                        placeholder='Phone*'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.tel}
                                    />
                                    {formik.touched.tel && formik.errors.tel ? (
                                        <div>{formik.errors.tel}</div>
                                    ) : null}
                                </div>
                            </div>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                placeholder='Email*'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div>{formik.errors.email}</div>
                            ) : null}
                            <input
                                id="referencia"
                                name="referencia"
                                type="referencia"
                                placeholder='How did you hear about us?*'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.referencia}
                            />
                            {formik.touched.referencia && formik.errors.referencia ? (
                                <div>{formik.errors.referencia}</div>
                            ) : null}
                            <input
                                id="project"
                                name="project"
                                type="project"
                                placeholder='Tell us about your project:*'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.project}
                            />
                            {formik.touched.project && formik.errors.project ? (
                                <div>{formik.errors.project}</div>
                            ) : null}
                            {/* <span>*Al descargar brochure acepta nuestros términos de privacidad.</span> */}
                            <div className='row width-recaptcha'>
                            <div className='recaptcha'>
                                <ReCAPTCHA
                                    // sitekey="6LcRQB8lAAAAABuX8GGQlV0hslfu08p2ShUUOE0d"
                                    sitekey='6LcYyjQaAAAAAGUiRnoKng7Z77D2I_MzefJ6TPM3'
                                    onChange={onChange}
                                />
                                <p className={`${errorRecaptcha}`}>Fill the recaptcha</p> 
                                </div>
                                <button type="submit">Submit</button>
                            </div>

                            </form>
                </div>
            </Form>
        </>
     );
}
 
const Form = styled.div`
width: 100%;
height: 100vh;
position: fixed;
z-index: 999;
background-color: rgba(0, 0, 0, 0.471);
overflow-y: auto;
display: flex;
justify-content: center;
align-items: center;
.contenedor_form{
    background-color: white;
    width: 90%;
    height: 90%;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    @media (min-width:1600px) {
        width: 80%;
        padding: 40px 60px;
    }
    @media (max-width:1024px) {
        width: 90%;
        height: 60%;
    }
    @media (max-width:768px) {
        width: 80%;
    }
    @media (max-width:550px) {
        width: 100%;
        min-height: 750px;
    }
    img{
        position: absolute;
        top: -50px;
        left: -50px;
        width: 350px;
    }
    h1{
        color: black;
        font-size: 38px;
        padding-top: 60px;
        font-family: "NeueHaasDisplayMedium";
        @media (max-width:550px) {
            text-align: center;
            font-size: 35px;
            padding-top: 20px;
        }
    }
    p{
        color: black;
        font-size: 20px;
        @media (max-width:550px) {
            text-align: center;
            font-size: 15px;
        }
    }
    span{
        color: black;
        font-size: 15px;
        @media (max-width:550px) {
            text-align: center;
            font-size: 12px;
        }
    }
    button{
        font-family: "HelveticaNeue";
        border: none;
        background-color:rgb(254, 238, 19);
        color: black;
        text-decoration: none;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 17px;
        &:hover{
            background-color: black;
            color: white;
        }
    }
    .closed{
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        font-size: 30px;
    }
    form{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 70%;
        @media (max-width:550px) {
            height: 60%;
            min-height: 420px;
            max-height: 420px;
        }
        div{
            color: red;
            font-size: 12px;
            font-family: "HelveticaNeue";
        }
        .row{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            &.width-recaptcha{
                width: 45%;
                @media (min-width: 1600px) {
                    width: 35%;
                }
                @media (max-width:550px) {
                    width: 100%;
                }
            }
            @media (max-width:550px) {
                width: 100%;
                flex-direction: column;
                row-gap: 13px;
            }
            .column{
                width: 48%;
                @media (max-width:550px) {
                    width: 100%;
                }
            }
        }
        input{
            font-family: "HelveticaNeue";
            width: 100%;
            height: 40px;
            border: 1px solid black;
            padding-left: 10px;
            color: black;
            font-size: 16px;
            outline: none;
            &::placeholder{
                padding-left: 10px;
                color: black;
                font-size: 16px;
            }
        }
    }
}
`
export default Formulario;