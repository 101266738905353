import React from 'react';
import { ModalVideo } from '../Styles/Styles';
const Modal = (props) => {
    return(
        <ModalVideo>
            <p onClick={()=>props.setPlay(false)}>X</p>
            <iframe muted autoPlay src="https://www.youtube.com/embed/4t7atu0Ciww?color=white"></iframe>
        </ModalVideo>
    );
}

export default Modal;