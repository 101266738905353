import React, { useEffect } from 'react';
import { ThankYou } from '../Styles/Styles';
import useDocumentTitle from '../Components/UseDocumentTitle';
const Thanks = () => {
    useDocumentTitle("Thank You | 3D World renderings, Inc.")
    useEffect(()=>{
        const activeNot = document.getElementsByClassName('activeService');
        if(activeNot.length == 1){
            activeNot[0].classList.remove('activeService')
        }
    })
    return ( 
        <div className='body-content'>
            <ThankYou>
                <div>
                    <h2>Thank you for your inquiry! </h2>
                    <p>We've received your message and will respond within the next 24 hours.</p>
                </div>
            </ThankYou>
        </div>
     );
}
 
export default Thanks;