import React from 'react';
import { Filtrado } from './style';
const Filtros = ({children,branding}) => {
    return ( 
        <Filtrado branding={branding}>
            {children}
        </Filtrado>
     );
}
 
export default Filtros;