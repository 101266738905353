import React,{ useEffect} from 'react';
import { TermsPrivacyStyle } from '../Styles/Styles';
import useDocumentTitle from '../Components/UseDocumentTitle';
const TermsPrivacy = () => {
    useDocumentTitle("Terms & Privacy | 3D World renderings, Inc.")
    useEffect(()=>{
        window.scrollTo(0, 0)
        const activeNot = document.getElementsByClassName('activeService');
        if(activeNot.length == 1){
            activeNot[0].classList.remove('activeService')
        }
    })
    return ( 
        <TermsPrivacyStyle className='body-content'>
           <div>
                <h2>Privacy Policy</h2>
                <p>
                    This privacy policy applies to the website <a href="https://3dworld.nyc/">https://3dworld.nyc/</a> and any subdomains. This website is owned and operated by 3D World renderings, Inc. and is intended for advertising purposes only. This privacy policy discloses practices related to (1) the collection and use of personally identifiable information, (2) your access to and control over your information, (3) the cookie policy for the website, (4) use of third-party links on this website, (5) use of affiliate links on this website, and (6) how you can contact us about any concerns you have related to your data privacy and this website.
                </p>
                <h4>Information Collection and Use</h4>
                <p>3D World renderings, Inc. may receive and retain personally identifiable information from users of this website via contact forms and email. Such information may include your name, email address, phone number, address, state, city, ZIP code and/or company. We may contact you directly or via newsletters or other marketing materials by using this information.</p>
                <p>Additionally, 3D World renderings, Inc. may collect usage and demographic data about how this website is found and/or interacted with. This information cannot be used to personally identify you and is used only for insights into the functionality and user-friendliness of the website. It may also be used in conjunction with the usage and demographic data of other users in the analysis of the relevancy of the content on the website and use trends. Usage data may include your IP address, browser information, device type, time and date of website visit, duration of website visit, and similar data.</p>
                <p>3D World renderings, Inc.  will not sell or rent personally identifiable information or usage and demographic data to any third parties. 3D World renderings, Inc.  may share anonymous usage and demographic data with affiliates, as described below, or with trusted third-party service providers only.</p>
                <p>You may opt out of receiving any marketing materials or communications from 3D World renderings, Inc.  at any time. You may contact us directly at any time to request access to, updates to, or deletion of your personally identifiable information.</p>
                <h4>Security of Information</h4>
                <p>3D World renderings, Inc.  takes all reasonable measures to ensure the security of information shared via contact forms and email on this site. However, we cannot guarantee that any electronic communication is absolutely secure.</p>
                <h4>Cookies</h4>
                <p>This website may use cookies and other tracking technology to gather information about site activity and to save certain information for ease of user experience. These may include a unique, anonymous identifier. You may set your website browser to refuse all cookies and tracking codes; however, this may mean that you cannot access certain services on this website.</p>
                <h4>Third-Party Links</h4>
                <p>This website may contain links to third-party sites. 3D World renderings, Inc.  takes reasonable precautions to ensure that external links are not malicious. However, we are not responsible for any content or practices on any external domains. We encourage users to take precautions when leaving this website by following third-party links and to review the privacy policy of any other site before use.</p>
                <h4>Affiliate Links</h4>
                <p>This website may contain links to websites of affiliates of 3D World renderings, Inc. These links may contain unique tracking codes and/or cookies. We encourage users to review the privacy policies of affiliate websites before use 3D World renderings, Inc.  does not have any control over or access to these codes and cookies.</p>
                <h4>Contact 3D World renderings, Inc. </h4>
                <p>If you have any questions or concerns about the privacy practices of 3D World renderings, Inc.  as they apply to this website <a href="https://3dworld.nyc/">https://3dworld.nyc/</a> or any applicable subdomains, then please call <a href="tel:2122037343">(212) 203-7343</a> or email us at <a href="mailto:info@3dworld.nyc">info@3dworld.nyc</a> </p>
                <h2>Terms of Use</h2>
                <p>The following are the terms of use that apply to the website <a href="https://3dworld.nyc/">https://3dworld.nyc/</a> and any subdomains owned and operated by 3D World renderings, Inc.  By accessing this website on any device, you agree to abide by the following terms and conditions. If you object to any modified condition or provision, then you may terminate your use of this website immediately.</p>
                <h4>Modification of Terms of Use</h4>
                <p>3D World renderings, Inc.  maintains the right to modify these terms of use at any time and for any reason by updating this page without directly notifying users of these changes. We encourage users to review these terms regularly. If you object to any modified condition or provision, then you may terminate your use of this website immediately. Continued use of the website indicates your agreement with these terms, including any modifications present on this page at the time of use.</p>
                <h4>Intellectual Property</h4>
                <p>Unless otherwise specified, any and all content, copy, logos and other visual assets on this website are the sole property of 3D World renderings, Inc.  Users are not permitted to distribute or use these assets without the express written permission of 3D World renderings, Inc. </p>
                <h4>Third-Party Links</h4>
                <p>This website may contain links to third-party sites. We encourage users to review the terms and conditions of any third-party sites before use.</p>
                <h4>User Limitations</h4>
                <p>By accessing this website, you agree to not use this site, any subdomain or any provided by the site or subdomain for any unlawful conduct, for any purpose prohibited by these terms, or for any purpose not reasonably intended by 3D World renderings, Inc. Prohibited uses include, but are not limited to, the following:</p>
                <ul>
                    <li>The abuse, harassment and/or impersonation of any other individual.</li>
                    <li>Communication with employees of 3D World renderings, Inc.  in a way that is offensive or abusive.</li>
                    <li>Any action intended to defraud an employee of 3D World renderings, Inc.  or any individual.</li>
                    <li>The creation or transmission of any unwanted spam content or links.</li>
                    <li>The violation of any existing trademark or copyright.</li>
                    <li>Any purpose that violates the laws of the jurisdiction where you access this website. Please note, this list is not exhaustive.</li>
                </ul>
                <h4>DCMA Notice</h4>
                <p>3D World renderings, Inc.  respects intellectual property rights and expects all users of this website to do the same. Pursuant to the Digital Millennium Copyright Act (DCMA), if you believe that your copyright-protected work appears on this website without permission, then you may report the alleged copyright infringement to 3D World renderings, Inc.  by using the contact information below. We will respond swiftly to any and all claims of copyright infringement. We will take whatever action is deemed appropriate to respond to these claims, including the removal of the protected content from the website.</p>
                <h4>Contact 3D World renderings, Inc. </h4>
                <p>If you have any questions or concerns about the terms and conditions listed above as they apply to this website <a href="https://3dworld.nyc/">https://3dworld.nyc/</a> or any subdomains, then please call <a href="tel:2122037343">(212) 203-7343</a> or email us at <a href="mailto:info@3dworld.nyc">info@3dworld.nyc</a> </p>
           </div>
        </TermsPrivacyStyle>
     );
}
 
export default TermsPrivacy;