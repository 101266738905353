import React, { useState, useEffect } from "react";
import FrontPage from "../../Components/Services/FrontPage";
import Filtros from "../../Components/Services/Filtros";
import ConceptService from "../../Components/Services/ConceptService";
import GaleryVideo from "../../Components/Services/GaleryVideo";
import { galeriesAnimation } from "../../utils/portaforio";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
const Animation = () => {
  const [gallery, setGallery] = useState(galeriesAnimation);
  let { pathname } = useLocation();
  const filtrosAnimation = [
    {
      title: "All",
      link: "/Animation-Services",
    },
    {
      title: "Architectural",
      link: "/Architectural-Animation-Services",
    },
    {
      title: "Product Design",
      link: "/Product-Design-Animation-Services",
    },
  ];
  const filtro = filtrosAnimation.filter((filtro) => filtro.link == pathname);
  useEffect(() => {
    if (pathname == "/Animation-Services") {
      setGallery(galeriesAnimation);
    } else {
      setGallery(
        galeriesAnimation.filter((filtro) => filtro.category == pathname)
      );
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="body-content">
      <Helmet>
        <title>Animation | 3D World renderings, Inc.</title>
        <meta
          name="description"
          content="Elevate your project with our dynamic animations. Enhance your digital strategy with visual marketing content that captivates and excites your audience. From fly-throughs to teasers, our animations bring your concept to life."
        />
      </Helmet>
      <FrontPage
        alt={"Animation Services - 3D World renderings, Inc"}
        portada={
          "/assets/img/services/Galeria/animation/Animation_Services-3D_World_rendering_Inc.jpg"
        }
        name={"Animation"}
        subtitule={"Services"}
      ></FrontPage>
      <ConceptService>
        <div className="title">
          {filtro.map((value, index) => {
            return (
              <>
                {value.link == "/Animation-Services" && (
                  <h2 key={index}>Our Animation</h2>
                )}
                {value.link !== "/Animation-Services" && (
                  <h2 key={index}>Our {value.title}</h2>
                )}
              </>
            );
          })}
          <h3>Services</h3>
        </div>
        <p className="animation">
          Our animation service will take your project to the next level. While
          renderings are a powerful tool to present your concept with,
          developing visual marketing content is critical in any digital
          strategy. Animations can provide a dynamic fly-through of your
          project, a better understanding of its spatial design, or a teaser
          that will captivate and excite your audience.
        </p>
      </ConceptService>
      <Filtros>
        <ul>
          {filtrosAnimation.map((val, index) => {
            return (
              <>
                {pathname == val.link && (
                  <>
                    <li key={index} className="active">
                      <Link to={val.link}>{val.title}</Link>
                    </li>
                    <div></div>
                  </>
                )}
                {pathname != val.link && (
                  <>
                    <li key={index}>
                      <Link to={val.link}>{val.title}</Link>
                    </li>
                    <div></div>
                  </>
                )}
              </>
            );
          })}
        </ul>
      </Filtros>
      <GaleryVideo type="Animation" gallery={gallery}></GaleryVideo>
    </div>
  );
};

export default Animation;
