import React, { useEffect } from "react";
import { ContentBrandingDetail } from "../../Styles/Styles";
import { useParams } from "react-router-dom";
import { porjectsBranding } from "../../utils/services/Branding/projects";
import useDocumentTitle from "../../Components/UseDocumentTitle";
import VideoPlayer from "../../Components/VideoPlayer";
const BrandingDetail = () => {
  const { brandingPath } = useParams();
  const branding = porjectsBranding.find((data) => data.path === brandingPath);
  useDocumentTitle(`${branding.name} | 3D World renderings, Inc.`);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ContentBrandingDetail>
      {branding.category !== "Marketing-Material-Branding-Services" && (
        <div className="hero-branding">
          <img src={branding.imgCoverDetail} alt="" />
          {branding.overlay && <div className="overlay-black"></div>}
        </div>
      )}
      {branding.sections &&
        branding.sections.map((sections, item) => (
          <React.Fragment key={item}>
            {sections.type === "paragraph" && (
              <div
                key={item}
                className={`paragraph ${sections.backgroundColor}`}
              >
                {sections.content &&
                  sections.content.map((text, index) => (
                    <div
                      key={index}
                      className={`${
                        index === 0 ? "paragraph-pad" : "flex-end"
                      } ${
                        text.description[0].font === "mini" && index === 0
                          ? "paragraph-pad-mini"
                          : ""
                      } ${text.className}`}
                    >
                      {text.line && <div className="linea"></div>}
                      {text.description &&
                        text.description.map((txt, ind) => (
                          <p key={ind} className={`txt-${txt.font}`}>
                            {txt.text}
                          </p>
                        ))}
                    </div>
                  ))}
              </div>
            )}
            {sections.type === "gallery" && (
              <div
                key={item}
                className={`${
                  branding.sections.length == 1 ? "just-one" : ""
                } grid-gallery`}
              >
                {sections.content &&
                  sections.content.map((gallery, index) => (
                    <React.Fragment key={index}>
                      {gallery.type === "img" && (
                        <img
                          className={`span-column-${gallery.column} span-row-${gallery.row}`}
                          src={gallery.url}
                          style={gallery.style ?? {}}
                          alt=""
                        />
                      )}
                      {gallery.type === "video" && (
                        <video
                          muted
                          autoPlay
                          loop
                          className={`span-column-${gallery.column} span-row-${gallery.row}`}
                          style={gallery.style ?? {}}
                          src={gallery.url}
                        ></video>
                      )}
                      {gallery.type === "iframe" && (
                        <iframe
                          className={`span-column-${gallery.column} span-row-${gallery.row}`}
                          src={gallery.url}
                          frameBorder="0"
                        ></iframe>
                      )}
                      {gallery.type === "vimeo" && (
                        <VideoPlayer
                          URL={gallery.url}
                          controls={true}
                          classe={`black span-column-${gallery.column} span-row-${gallery.row}`}
                          VIDEO_WIDTH={"100%"}
                          VIDEO_HEIGHT={"100%"}
                        ></VideoPlayer>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            )}
          </React.Fragment>
        ))}
    </ContentBrandingDetail>
  );
};

export default BrandingDetail;
