import { forwardRef, useImperativeHandle, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Field, ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import swal from "sweetalert";

import { FormActions, GetAQuoteFormStyled, SubmitButton } from "./styled";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const GET_A_QUOTE_FORM_SCRIPT = process.env.REACT_APP_GET_A_QUOTE_FORM_SCRIPT;

const REQUIRED_FIELD = "Required field";
const INVALID_EMAIL = "Invalid email";

const formSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_FIELD),
  phone: yup
    .string()
    .min(10, "Must be at least 10 digits")
    .max(10, "Must be 10 digits maximum")
    .required(REQUIRED_FIELD),
  email: yup.string().email(INVALID_EMAIL).required(REQUIRED_FIELD),
  project: yup.string().required(REQUIRED_FIELD),
  recaptcha: yup.string().required("Solve recaptcha").nullable(),
});

const initialFormValues = {
  name: "",
  phone: "",
  email: "",
  project: "",
  recaptcha: "",
};

export const GetAQuoteForm = forwardRef(({ onSubmit }, forwardedRef) => {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const recaptchaRef = useRef(null);

  const cleanForm = () => {
    formikRef?.current?.resetForm();
    recaptchaRef?.current?.reset();
  };

  // Expose a single method to cleanup the form from the outside
  useImperativeHandle(
    forwardedRef,
    () => ({
      cleanForm,
    }),
    []
  );

  const handleSubmit = async (values) => {
    const response = await axios.post(GET_A_QUOTE_FORM_SCRIPT, values);
    const responseData = response.data;

    if (responseData.success) {
      navigate("/ThankYou");
    }

    if (responseData.error) {
      swal("Error", "There was a problem sending the email", "error");
      cleanForm();
    }

    if (onSubmit) {
      onSubmit(responseData);
    }
  };

  return (
    <GetAQuoteFormStyled>
      <Formik
        innerRef={formikRef}
        initialValues={initialFormValues}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, setFieldValue, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Field id="name" name="name" placeholder="Name*" />
            <ErrorMessage name="name" />
            <Field id="email" name="email" placeholder="Email*" />
            <ErrorMessage name="email" />
            <Field id="phone" name="phone" placeholder="Phone*" />
            <ErrorMessage name="phone" />
            <label htmlFor="project">Tell us about your project</label>
            <Field id="project" name="project" as="textarea" row="3"></Field>
            <ErrorMessage name="project" />
            <FormActions>
              <div>
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY}
                  ref={recaptchaRef}
                  hl="en"
                  onChange={(recaptchaValue) =>
                    setFieldValue("recaptcha", recaptchaValue)
                  }
                />
                <ErrorMessage name="recaptcha" />
              </div>
              <div>
                <SubmitButton
                  type="submit"
                  weight
                  opacity="true"
                  font
                  size
                  absolute={false}
                  portada
                  disabled={isSubmitting}
                >
                  {isSubmitting && "Sending..."}
                  {!isSubmitting && "Submit"}
                </SubmitButton>
              </div>
            </FormActions>
          </Form>
        )}
      </Formik>
    </GetAQuoteFormStyled>
  );
});
