import React, { useEffect, useState } from "react";
import { ContentFooter } from "../Styles/Styles";
import { Link } from "react-router-dom";
const Footer = (props) => {
  const [mobile, setMobile] = useState(false);
  const [path, setPath] = useState(window.location.pathname);
  const [hideButton, setHideButton] = useState(false);
  const currentYear = new Date().getFullYear();
  window.addEventListener("resize", function (event) {
    if (window.innerWidth <= 550) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  });

  useEffect(() => {
    // console.log(path)
    if (path === "/ThankYou") {
      setHideButton(true);
    }
    if (window.innerWidth <= 550) {
      setMobile(true);
    }
  }, []);

  return (
    <ContentFooter id="footer">
      <div className="top">
        <div className="left">
          <div className="row">
            <h2>Let’s get started</h2>
            {
              !mobile && !hideButton && <Link to="/Contact">Get a Quote</Link>
              // <a onClick={()=>props.setShowModal(true)}>Get a Quote</a>
            }
          </div>

          <p>
            A New York City creative studio, we make experiencing your ideas
            possible.
          </p>
          <div className="social">
            <a target={"_blank"} href="https://www.instagram.com/3dworldny/">
              <img src="/assets/img/insta-blanco.png" alt="Instagram" />
            </a>
            <a
              target={"_blank"}
              href="https://www.linkedin.com/company/3dworldny"
            >
              <img src="/assets/img/linkedin-white.png" alt="Linkedin" />
            </a>
          </div>
          {
            mobile && !hideButton && (
              <Link className="button_mobile" to="/Contact">
                Get a Quote
              </Link>
            )
            // <a className='button_mobile' onClick={()=>props.setShowModal(true)}>Get a Quote</a>
          }
        </div>
      </div>
      <div className="bottom">
        <div className="address">
          <div className="city">
            <p>NEW YORK</p>
            <a target={"_blank"} href="https://goo.gl/maps/cxq1PyXEu3iHbgfe9">
              33 Irving Place <br />
              New York, NY 10003
            </a>
            <a target={"_blank"} href="tel:2122037343">
              212-203-7343
            </a>
            {mobile && (
              <a target={"_blank"} href="https://goo.gl/maps/cxq1PyXEu3iHbgfe9">
                Map
              </a>
            )}
          </div>
          <a target={"_blank"} href="https://goo.gl/maps/cxq1PyXEu3iHbgfe9">
            <img
              src="/assets/img/33_Irving_place_3D_World_renderings_Inc_NYC_office.jpeg"
              alt="33 Irving place - 3D World renderings, Inc. NYC office"
            />
          </a>
          <div className="city">
            <p>AUSTIN</p>
            <a target={"_blank"} href="https://goo.gl/maps/sUiXK1FS2Zrqc9GS9">
              106 E 6th St <br />
              Austin, TX 78701
            </a>
            <a target={"_blank"} href="tel:5125372809">
              512-537-2809
            </a>
            {mobile && (
              <a target={"_blank"} href="https://goo.gl/maps/sUiXK1FS2Zrqc9GS9">
                Map
              </a>
            )}
          </div>
          <a target={"_blank"} href="https://goo.gl/maps/sUiXK1FS2Zrqc9GS9">
            <img
              src="/assets/img/106_E_6th-3D_World_renderings_Inc_ATX_office.jpeg"
              alt="106 E 6th - 3D World renderings, Inc. ATX office"
            />
          </a>
        </div>
        <div className="datos">
          {mobile && (
            <Link className="subscribe" to="/Contact">
              Subscribe to our Newsletter
            </Link>
          )}
          <div>
            <a className="icono-footer" href="/">
              <img src="/assets/img/logo-white.png" alt="" />
            </a>
            {!mobile && (
              <Link className="subscribe" to="/Contact">
                Subscribe to our Newsletter
              </Link>
            )}
            <p>
              &copy; {currentYear} 3D World Renderings, Inc. All Rights Reserved{" "}
              <span>|</span> <Link to={"/Terms-Privacy"}>Terms & Privacy</Link>{" "}
              <span>|</span>{" "}
              <a href="mailto: info@3dworld.nyc">info@3dworld.nyc</a>{" "}
            </p>
          </div>
        </div>
      </div>
    </ContentFooter>
  );
};

export default Footer;
