import React from 'react';
import { ContentAssociated } from '../Styles/Styles';
import {Link } from "react-router-dom";
const Associated = (props) => {
    // console.log(props)

    const titulo = (titulo) =>{

    }
    return ( 
        <ContentAssociated>
            {
                props.array.map((item,index) =>{
                   return(
                    <div key={index} className='card'>
                        <Link to={`/News/${item.slug_article}`}>
                            {/* <img src={`http://127.0.0.1:8000${item.url_front}`} alt="" /> */}
                            <img src={`https://panelblog.3dworld.nyc${item.url_front}`} alt="" />
                        </Link>
                        <p>
                        <Link className='imagen_link' to={`/News/${item.slug_article}`}>{item.title_article}</Link>
                        </p>
                    </div>
                   )
                })
            }
        </ContentAssociated>
     );
}
 
export default Associated;