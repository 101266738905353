import React,{useEffect} from 'react';
import { useParams } from "react-router-dom";
import { galeriesAnimation, galeriesVFX, galeriesVirtual } from '../../utils/portaforio';
import VideoPlayer from '../../Components/VideoPlayer';
import ReactPannellum, { getConfig } from "react-pannellum";
import useDocumentTitle from '../../Components/UseDocumentTitle';
const ServiceDetail = () => {
    const routeParams = useParams();
    useDocumentTitle(`${routeParams.serviceId} | 3D World renderings, Inc.`)
    // console.log(routeParams)
    useEffect(()=>{
        window.scrollTo(0, 0)

    },[])
    const config = {
        autoRotate: -2,
        autoLoad: true,
        showControls: false,
        showZoomCtrl: false,
        mouseZoom: false
    };
    return ( 
        <div className='body-content'>
            {
                 galeriesVFX.map((val,index)=>{
                    if(val.path === routeParams.serviceId){
                        return(
                            <div key={index} className='detalle_video'>
                                <VideoPlayer  URL={val.url} VIDEO_WIDTH={'100%'} VIDEO_HEIGHT={'100%'} mute={false} controls={true}></VideoPlayer>
                            </div>
                        )
                    }
                 })
            }
            {
                 galeriesAnimation.map((val,index)=>{
                    if(val.path === routeParams.serviceId){
                        return(
                            <div key={index} className='detalle_video'>
                                <VideoPlayer  URL={val.url} VIDEO_WIDTH={'100%'} VIDEO_HEIGHT={'100%'} mute={false} controls={true}></VideoPlayer>
                            </div>
                        )
                    }
                 })
            }
            {
                 galeriesVirtual.map((val,index)=>{
                    if(val.path === routeParams.serviceId){
                        return(
                            <div key={index} className='detalle_video'>
                                <ReactPannellum
                                    id="firstScene"
                                    sceneId="firstScene"
                                    imageSource={val.url360}
                                    config={config}
                                    />
                            </div>
                        )
                    }
                 })
            }
        </div>
     );
}
 
export default ServiceDetail;