import styled from "styled-components";
import { Button, FormContact } from "../../Styles/Styles";

// TODO: Make this styled component global
export const Title = styled.h2`
  padding: 0 0 25px 0;
  color: black;
  font-size: 28px;
  font-family: "NeueHaasDisplayMedium";
  display: flex;
  flex-direction: column;
  &::after {
    content: " ";
    margin-top: 10px;
    width: 100px;
    height: 2px;
    background-color: black;
  }
`;

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  margin-right: 0px !important;
  cursor: pointer;
  position: relative;
  background: transparent;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-items: center;
  position: relative;

  &::before,
  &::after {
    content: "";
    transform: translate(-50%, -50%);
    display: block;
    height: 1px;
    width: 100%;
    background-color: black;
    transition: width 0.5s;
    position: absolute;
  }

  &::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%) rotate(-45deg);
    background-color: black !important;
  }
  &::after {
    top: 50%;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    background-color: black !important;
  }
  &::before,
  &::after {
    transition: all 0.3s;
  }
`;

export const GetAQuoteButton = styled(Button)`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.23);
  z-index: 50;
  transition: all 0.3s ease-in-out;
  transform: translateX(100%);
  opacity: 0;
  font-size: 18px;
  text-align: center;
  // Pixel perfect FTW
  width: 151px;
  ${({ $isVisible }) =>
    $isVisible &&
    `
    transform: translateX(0%);
    opacity: 1;
  `};

  @media (min-width: 1025px) {
    bottom: 1.75rem;
    right: 1.75rem;
  }
`;

export const GetAQuoteFormStyled = styled(FormContact)`
  padding: 0;
  margin: 0;
  form {
    width: 100%;
    font-size: 14px;

    input,
    textarea {
      width: 100%;
      /* font-size: 18px; */
      &:not(:first-child) {
        margin-top: 25px;
      }
      &:first-child {
        margin-top: 15px;
      }
    }
    label {
      /* font-size: 18px; */
    }
    button {
      margin: 0;
      font-size: 18px;
    }

    input,
    label,
    textarea {
      font-size: 16px;
    }

    @media (min-width: 640px) {
      & {
        font-size: 16px;
      }

      input,
      label,
      textarea,
      button {
        font-size: 18px;
      }
    }
  }
`;

export const GetAQuotePopoverDrawer = styled.div`
  @media (min-width: 640px) {
    padding: 30px 30px 30px 45px;
    transform: ${({ $isVisible }) =>
      $isVisible ? `translateX(0%)` : `translateX(100%)`};
  }

  position: fixed;
  z-index: 100;
  width: 500px;
  max-width: 100%;
  bottom: 0;
  right: 0;
  padding: 30px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.23);
  /* TODO: Tokenize this color */
  background-color: #f1f0eb;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${({ $isVisible }) =>
    $isVisible ? `translateY(0%)` : `translateY(100%)`};

  ${CloseButton} {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  ${Title} {
    font-size: 22px;
    margin-top: 15px;

    @media (min-width: 640px) {
      & {
        font-size: 28px;
        margin-top: 30px;
      }
    }
  }
`;

export const FormActions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: end;
  gap: 10px;
  margin-top: 15px;

  @media (min-width: 480px) {
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    gap: 15px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 49;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const SubmitButton = Button.withComponent("button");
